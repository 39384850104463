.app__cookie-dialog {
	position: fixed;
	bottom: 67px;
	left: 0;
	width: 100%;
	background-color: #fff;
	border-top: 1px solid #ccc;
	padding: 1rem;
  padding-left: 5rem;
  padding-right: 5rem;
	z-index: 1000;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 0.8rem;
	box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
	transform: translateY(100%);
	transition: transform 0.3s ease-in-out;
	&.active {
		transform: translateY(0);
	}
	.app__cookie-dialog__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 6px;
	}
	.app__cookie-dialog__text {
		max-width: 80%;
	}
	.app__cookie-dialog__button {
		background-color: #fff;
		border: 1px solid #ccc;
		padding: 0.5rem 1rem;
		cursor: pointer;
		transition: all 0.3s ease-in-out;
		&:hover {
			background-color: #ccc;
		}
	}
	.app__cookie-dialog__button-close {
		padding: 0.2rem 0.5rem;
		border-radius: 25px;
		position: absolute;
		top: 0;
		right: 0;
	}
}

.app__cookie-dialog--hidden {
	display: none;
}
