:root {
	--footer-card-color: var(--gray-color);
	--footer-card-bg1: #fef4f5;
	--footer-card-bg2: #f2f7fb;
}

body.dark {
	--footer-card-color: var(--white-color);
	--footer-card-bg1: #ffbfc5;
	--footer-card-bg2: #77bcf3;
}

.app__footer {
	flex: 1;
	width: 100%;
	flex-direction: column;

	@media screen and (max-width: 768px) {
		flex-direction: column;
	}
}

.app__footer-cards {
	width: 60%;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	flex-wrap: wrap;
	margin: 4em 2rem 2rem;

	.app__footer-card {
		min-width: 290px;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;

		margin: 1rem 0;
		padding: 1rem;
		border-radius: 10px;
		cursor: pointer;
		background-color: var(--footer-card-bg1);

		transition: all 0.3s ease-in-out;

		img {
			width: 40px;
			height: 40px;
			margin: 0 0.7rem;
		}

		p {
			font-weight: 500;
		}
		a {
			text-decoration: none;
			font-weight: 500;
		}
		&:hover {
			box-shadow: 0 0 25px var(--footer-card-bg1);
		}

		@media screen and (max-width: 450px) {
			width: 100%;
		}
	}

	.p-text {
		color: var(--footer-card-color);
	}

	@media screen and (max-width: 768px) {
		width: 100%;
	}
}

.app__footer-cards .app__footer-card:last-child {
	background-color: var(--footer-card-bg2);
}

.app__footer-cards .app__footer-card:last-child:hover {
	box-shadow: 0 0 25px var(--footer-card-bg2);
}

.app__footer-social {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;

	a {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		background-color: var(--white-color);
		margin: 0.25rem 0;
		border: 1px solid var(--lightGray-color);

		display: flex;
		justify-content: center;
		align-items: center;

		transition: all 0.3s ease-in-out;

		svg {
			width: 15px;
			height: 15px;
			color: var(--gray-color);
		}

		&:hover {
			background-color: var(--secondary-color);
			border-color: var(--secondary-color);

			svg {
				color: var(--white-color);
			}
		}

		@media screen and (min-width: 2000px) {
			width: 70px;
			height: 70px;

			margin: 0.5rem 0;

			svg {
				width: 30px;
				height: 30px;
			}
		}
	}
}

.app__footer-form {
	width: 60%;
	flex-direction: column;
	margin: 1rem 2rem;

	div {
		width: 100%;

		margin: 0.75rem 0;
		border-radius: 10px;
		cursor: pointer;
		background-color: var(--primary-color);

		transition: all 0.3s ease-in-out;

		input,
		textarea {
			width: 100%;
			padding: 0.95rem;
			border: none;
			border-radius: 7px;
			background-color: var(--primary-color);

			font-family: var(--font-base);
			color: var(--secondary-color);
			outline: none;
		}

		textarea {
			height: 170px;
		}

		&:hover {
			box-shadow: 0 0 25px var(--primary-color);
		}
	}

	button {
		padding: 1rem 2rem;
		border-radius: 10px;
		border: none;
		background-color: var(--secondary-color);

		font-weight: 500;
		color: var(--bluewhite-color);
		outline: none;
		margin: 2rem 0 0 0;
		font-family: var(--font-base);

		transition: cubic-bezier(0.55, 0.085, 0.68, 0.53);
		cursor: pointer;

		&:hover {
			background-color: #2430af;
		}
	}

	@media screen and (max-width: 768px) {
		width: 100%;
		margin: 1rem 0;
	}
}
