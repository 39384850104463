:root {
	--projects-bg: #fff;
	--projects-color: var(--black-color);
	--projects-hover-color: rgba(0, 0, 0, 0.5);
}

body.dark {
	--projects-bg: var(--lightGray-color);
	--projects-color: var(--black-color);
	--projects-hover-color: rgba(255, 255, 255, 0.5);
}

.app__projects {
	flex: 1;
	width: 100%;
	flex-direction: column;
}

.app__project-filter {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;

	margin: 4rem 0 2rem;

	.app__project-filter-item {
		padding: 0.5rem 1rem;
		border-radius: 0.5rem;
		background-color: var(--projects-bg);
		color: var(--projects-color);
		font-weight: 800;

		cursor: pointer;
		transition: all 0.3s ease;
		margin: 0.5rem;

		&:hover {
			background-color: var(--secondary-color);
			color: #fff;
		}

		@media screen and (min-width: 2000px) {
			padding: 1rem 2rem;
			border-radius: 0.85rem;
		}
	}

	.item-active {
		background-color: var(--secondary-color);
		color: #fff;
	}
}

.app__project-portfolio {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;

	.app__project-item {
		width: 270px;
		flex-direction: column;

		text-decoration: none;

		margin: 2rem;
		padding: 1rem;
		border-radius: 0.5rem;
		background-color: var(--projects-bg);
		color: var(--projects-color);

		cursor: pointer;
		transition: all 0.3s ease;

		&:hover {
			box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
		}

		@media screen and (min-width: 2000px) {
			width: 470px;
			padding: 1.25rem;
			border-radius: 0.75rem;
		}

		@media screen and (max-width: 300px) {
			width: 100%;
			margin: 1rem;
		}
	}
}

.app__project-img {
	width: 100%;
	height: 230px;

	position: relative;

	img {
		width: 100%;
		height: 100%;
		border-radius: 0.5rem;
		object-fit: cover;
	}

	@media screen and (min-width: 2000px) {
		height: 350px;
	}
}

.app__project-hover {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background-color: var(--projects-hover-color);

	border-radius: 0.5rem;
	opacity: 0;
	transition: all 0.3s ease;

	div {
		width: 50px;
		height: 50px;
		border-radius: 50%;
		background-color: var(--projects-hover-color);
		color: var(--projects-color);

		margin: 1rem;
		font-family: var(--font-base);
		font-weight: 800;
		cursor: pointer;
		transition: all 0.3s ease;

		svg {
			width: 50%;
			height: 50%;
			color: var(--white-color);
		}
	}
}

.app__project-content {
	padding: 0.5rem;
	width: 100%;
	position: relative;
	flex-direction: column;

	h4 {
		margin-top: 1rem;
		line-height: 1.5;

		@media screen and (min-width: 2000px) {
			margin-top: 3rem;
		}
	}

	.app__project-tag {
		position: absolute;

		padding: 0.5rem 1rem;
		border-radius: 10px;
		background-color: var(--projects-bg);
		top: -25px;
	}
}

.app__buttons-between {
	align-items: center;
	display: flex;
	justify-content: space-between;
	width: 100%;
	text-align: center;
}

.app__buttons-between-mobile {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	display: none;
}

@media screen and (max-width: 768px) {
	.app__buttons-between-mobile {
		display: flex;
	}

	.app__buttons-between {
		justify-content: center;
		a {
			display: none;
		}
	}
}

.m-0 {
	margin: 0;
}