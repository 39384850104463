body {
	margin: 0;
	padding: 0;
}

.app__loader {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: var(--white-color);
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: opacity 0.3s ease-in-out;
	overflow: hidden !important;
	opacity: 1;
	&.app__loader--hidden {
		opacity: 0;
		pointer-events: none;
	}
	.app__loader-spinner {
		width: 512px;
		height: 512px;
	}
	img {
		width: 100%;
		height: 100%;
		animation: spin 2s linear infinite;
		border-radius: 25px;
	}
}

.app__loader-copyright {
	position: absolute;
	bottom: 5px;
	right: 5px;
}

// Spin animation
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
